.resetpassword-wrapper {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .logoBox {
    width: 180px;
    display: inline-block;
    margin: 10px 0;
  }
  .resetpassword-form {
    background: #fff;
    box-shadow: 0 0 5px #c4c4c4;
    display: inline-block;
    border-radius: 15px;
    padding: 40px;
    width: 400px;
    max-width: 95%;
    h3 {
      margin-bottom: 20px;
      text-align: center;
      font-size: 30px;
      color: #00949e;
    }
    .input-box {
      position: relative;
      border: 1px solid #00bac7;
      border-radius: 5px;
      padding: 4px;
      margin: 20px 0;
      display: flex;
      align-items: center;
      input {
        border: none;
        padding: 5px;
        padding-left: 30px;
        display: block;
        width: 100%;
        font-size: 16px;
      }
      svg {
        color: #00bac7;
        font-size: 18px;
        position: absolute;
        left: 8px;
      }
      .show-password {
        cursor: pointer;
        position: absolute;
        right: 34px;
        color: #00bac7;
        top: 13px;
        svg {
          font-size: 20px;
        }
      }
    }
    button {
      background: #00bac7;
      border: 2px solid #00bac7;
      border-radius: 5px;
      display: block;
      width: 100%;
      font-size: 17px;
      margin: 15px 0;
      color: #fff;
      padding: 8px 0;
      transition: 0.4s all;
      cursor: pointer;
      .loading-screen.small {
        font-size: 16px;
        padding-top: 0;
        text-transform: capitalize;
        .dot-typing {
          width: 8px;
          height: 8px;
        }
      }
      &:hover,
      &:focus {
        background: #fff;
        color: #00aab6;
      }
      &.disabled,
      &.disabled:hover,
      &.disabled:focus {
        pointer-events: none;
        background: #fff;
        color: #00aab6;
      }
    }
  }
}
