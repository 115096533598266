.course-video-question-wrapper {
  background: url(.../../../../../../../../assets/backgrounds/question.png);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .audio-wrapper {
    width: 40%;
    background: #fff;
    padding: 10px;
    margin: 30px auto;
    border-radius: 20px;
    overflow: hidden;
    .rhap_container {
      box-shadow: none;
    }
  }
  .open-ended {
    min-height: 260px;
    .title {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-weight: 600;
      color: #18223a;
      justify-content: center;
      img {
        width: 50px;
        margin-right: 10px;
        @media screen and (max-width: 763px) {
          width: 30px;
          margin-right: 5px;
        }
      }
      @media screen and (max-width: 763px) {
        font-size: 17px;
        padding-top: 20px;
      }
    }
    .question {
      margin: 20px auto;
    }
    .options {
      height: auto;
      padding: 20px 0;
      li {
        width: 280px;
        max-width: 70%;
        margin: auto;
        display: block;
        .checkbox-container {
          font-size: 18px;
          color: #424955;
          font-weight: 600;
          margin-right: 20px;
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 45px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          .answer-progress-bar-wrapper {
            border-radius: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 80%;
            height: 28px;
            .answer-progress-bar {
              position: relative;
              height: 100%;
              .progress {
                min-width: 15%;
                transition: 0.4s all;
                background: #ffaf03;
                border-radius: 20px;
                height: 100%;
                float: left;
                .box {
                  top: -6px;
                  width: 40px;
                  position: relative;
                  left: -5px;
                  border-radius: 30px;
                  height: 40px;
                  background: #d3f1f3;
                }
              }
              span {
                padding-left: 15px;
              }
            }
          }
          label {
            display: flex;
            z-index: 2;
            align-items: center;
            .thinking {
              width: 30px;
              display: inline-block;
              margin-right: 10px;
              transform: scaleX(-1);
            }
            img {
              max-width: 30px;
              height: auto !important;
            }
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
              background-color: #00bac7;
              border: 1px solid #00bac7;
            }
            &:checked ~ .checkmark::after {
              display: block;
            }
          }
          .checkmark {
            position: absolute;
            top: -2px;
            left: 0;
            height: 30px;
            border-radius: 50px;
            width: 30px;
            cursor: pointer;
            background-color: #fff;
            border: 1px solid #fff;
            &::after {
              content: "";
              position: absolute;
              left: 10px;
              top: 6px;
              width: 6px;
              display: none;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              @media screen and (max-width: 763px) {
                left: 8px;
                width: 4px;
                height: 9px;
              }
            }
            &:hover input ~ .checkmark {
              background-color: #ccc;
            }
            @media screen and (max-width: 763px) {
              width: 25px;
              height: 25px;
            }
          }

          @media screen and (max-width: 763px) {
            font-size: 16px;
          }
        }
        @media screen and (max-width: 763px) {
          width: 100%;
        }
      }
      @media screen and (max-width: 763px) {
        padding-bottom: 20px;
      }
    }
    @media screen and (max-width: 763px) {
      min-height: 220px;
      .checkbox-container {
        padding-left: 35px !important;
        .checkmark {
          top: auto !important;
        }
      }
    }
  }
  .title {
    text-align: center;
    margin-top: 35px;
    @media screen and (max-width: 763px) {
      margin-top: 10px;
    }
  }
  .question {
    font-size: 20px;
    font-weight: 500;
    width: 80%;
    min-height: auto;
    margin: auto;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-bottom: 20px;
    @media screen and (max-width: 763px) {
      margin-bottom: 10px;
      min-height: 50px;
      font-size: 14px;
    }
  }
  .options-wrapper {
    position: relative;
    margin-left: 18%;
    height: 130px;
    .scroll-down {
      bottom: 0;
      position: absolute;
      height: 40px;
      width: 25px;
      background: #fff;
      right: 40px;
      // border: 2px solid #009aa5;
      border-radius: 40px;
      cursor: pointer;
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 20%;
        left: 50%;
        height: 7px;
        width: 7px;
        transform: translate(-50%, -100%) rotate(45deg);
        border: 2px solid #009aa5;
        border-top: transparent;
        border-left: transparent;
        animation: scroll-down 1s ease-in-out infinite;
      }
      &::before {
        top: 30%;
        animation-delay: 0.3s;
      }
    }
    .scroll-up {
      bottom: 0;
      position: absolute;
      height: 40px;
      width: 25px;
      background: #fff;
      right: 40px;
      // border: 2px solid #009aa5;
      border-radius: 40px;
      cursor: pointer;
      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: 5%;
        left: 50%;
        height: 7px;
        width: 7px;
        transform: translate(-50%, -10%) rotate(45deg);
        border: 2px solid #009aa5;
        border-bottom: transparent;
        border-right: transparent;
        animation: scroll-up 1s ease-in-out infinite;
      }
      &::before {
        bottom: 10%;
        animation-delay: 0.3s;
      }
    }
    .options {
      padding-top: 5px;
      margin-left: 0;
      height: 100%;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background: #348a90;
        border-radius: 10px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #24696e;
      }

      @media screen and (max-width: 763px) {
        height: 90px;
        margin-left: 10%;
        padding: 0;
      }
      li {
        width: 95%;
        z-index: 9;
        display: block;
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        @media screen and (max-width: 763px) {
          margin-bottom: 10px;
        }
        .checkbox-container {
          font-size: 18px;
          color: #424955;
          font-weight: 600;
          margin-right: 20px;
          position: relative;
          display: flex;
          align-items: center;
          padding-left: 45px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          .answer-progress-bar-wrapper {
            border-radius: 20px;
            position: absolute;
            top: 0;
            left: 0;
            width: 80%;
            height: 28px;
            .answer-progress-bar {
              position: relative;
              height: 100%;
              .progress {
                min-width: 15%;
                transition: 0.4s all;
                background: #ffaf03;
                border-radius: 20px;
                height: 100%;
                float: left;
                .box {
                  top: -6px;
                  width: 40px;
                  position: relative;
                  left: -5px;
                  border-radius: 30px;
                  height: 40px;
                  background: #d3f1f3;
                }
              }
              span {
                padding-left: 15px;
              }
            }
          }
          label {
            display: flex;
            z-index: 2;
            // min-width: 7%;
            align-items: center;
            .thinking {
              width: 30px;
              display: inline-block;
              margin-right: 10px;
              transform: scaleX(-1);
            }
            img {
              max-width: 30px;
              height: auto !important;
            }
          }
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &:checked ~ .checkmark {
              background-color: #00bac7;
              border: 1px solid #00bac7;
            }
            &:checked ~ .checkmark::after {
              display: block;
            }
          }
          .checkmark {
            position: absolute;
            top: -2px;
            left: 0;
            height: 30px;
            border-radius: 50px;
            width: 30px;
            cursor: pointer;
            background-color: #fff;
            border: 1px solid #fff;
            &::after {
              content: "";
              position: absolute;
              left: 10px;
              top: 6px;
              width: 6px;
              display: none;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
              @media screen and (max-width: 763px) {
                left: 8px;
                width: 4px;
                height: 9px;
              }
            }
            &:hover input ~ .checkmark {
              background-color: #ccc;
            }
            @media screen and (max-width: 763px) {
              width: 25px;
              height: 25px;
            }
          }
          // &.active {
          //   input {
          //     &:checked ~ .checkmark {
          //       background-color: #fff;
          //       border: 1px solid #fff;
          //     }
          //   }
          //   .checkmark {
          //     position: absolute;
          //     top: -2px;
          //     left: 0;
          //     height: 30px;
          //     border-radius: 50px;
          //     width: 30px;
          //     cursor: auto !important;
          //     &::after {
          //       content: "";
          //       position: absolute;
          //       left: 10px;
          //       top: 6px;
          //       width: 6px;
          //       display: none;
          //       height: 12px;
          //       border: solid #00bac7;
          //       border-width: 0 2px 2px 0;
          //       -webkit-transform: rotate(45deg);
          //       -ms-transform: rotate(45deg);
          //       transform: rotate(45deg);
          //     }
          //   }
          // }

          @media screen and (max-width: 763px) {
            font-size: 16px;
          }
        }
      }
    }
    @media screen and (max-width: 763px) {
      margin-left: 0;
      height: 100px;
    }
  }
  .ques-video-wrapper {
    width: 400px;
    max-width: 90%;
    height: 280px !important;
    margin: 20px auto;
    border-radius: 10px;
    overflow: hidden;
  }
  .question-wait {
    text-align: center;
    width: 90%;
    margin: auto;
    font-size: 16px;
    font-weight: 500;
    color: #6d768c;
    span {
      color: #00bac7;
    }
  }
  .button-wrapper {
    padding-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      position: relative;
      min-width: 153px;
      border: 1px solid #00bac7;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 14px;
      padding: 8px 0;
      font-weight: 500;
      text-transform: none;
      margin: 0 14px;
      transition: 0.3s all;
      &:nth-child(1) {
        color: #00bac7;
        &:hover {
          background: #00bac7;
          color: #fff;
        }
      }
      &:nth-child(2) {
        background: #00bac7;
        color: #fff;
        &:hover {
          background: #009aa5;
          border: 1px solid #009aa5;
        }
      }
      &.skip-question {
        position: absolute;
        border: none;
        right: 0;
        font-size: 15px;
        color: #00bac7;
        &:hover {
          color: #009aa5;
        }
        @media screen and (max-width: 763px) {
          position: unset;
          margin-top: 5px;
          font-size: 14px;
        }
      }
      @media screen and (max-width: 763px) {
        min-width: 40%;
        font-size: 13px;
        padding: 7px 0;
      }
    }
    @media screen and (max-width: 763px) {
      display: block;
      text-align: center;
    }
  }
}

@keyframes scroll-down {
  0% {
    /* top:20%; */
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    top: 90%;
    opacity: 0;
  }
}

@keyframes scroll-up {
  0% {
    /* top:20%; */
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    bottom: 70%;
    opacity: 0;
  }
}
