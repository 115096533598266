.facebook-privacy-policy {
  text-align: center;
  
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: url("../../assets/backgrounds/404.png") #00bac7;
  .content {
    padding-top: 60px;
    width: 900px;
    max-width: 100%;

    h1 {
      font-size: 30px;
      color: #fff;
      font-weight: 900;
      line-height: 1em;
      text-align: left;
    }
  
    p {
      text-align: justify;
      font-size: 18px;
      font-weight: lighter;
      margin-bottom: 10px;
      span{
        font-weight: bold;
      }
    }

  
  }
}
