.privacy {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 40px 20px;
  background: url("../../assets/backgrounds/404.png") #00bac7;
  background-size: cover;
  color: white;

  .content {
    padding: 40px;
    width: 100%;
    max-width: 900px;
    border-radius: 10px;

    h1 {
      font-size: 2em;
      color: #fff;
      font-weight: 900;
      line-height: 1.2em;
      text-align: center;
      margin-bottom: 20px;
    }
  
    h3 {
      font-size: 1.5em;
      color: #fff;
      margin-top: 20px;
      margin-bottom: 10px;
      font-weight: bold;
    }

    p, li {
      text-align: justify;
      font-size: 18px;
      margin-bottom: 15px;

      span {
        font-weight: bold;
      }
    }

    ul {
      list-style: disc;
      padding-left: 20px;
      margin: 20px 0;

      li {
        margin-bottom: 10px;
      }
    }

    a {
      display: inline-block;
      padding: 10px 20px;
      background: #007179;
      color: #fff;
      text-decoration: none;
      margin-top: 20px;
      border-radius: 8px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: bold;
      transition: 0.3s all;
      text-align: center;

      &:hover {
        background: #00565c;
      }
    }
  }
}
