@import url("https://fonts.googleapis.com/css2?family=Playball&display=swap");
.course-video-ended-wrapper {
  background: url(.../../../../../../../../assets/backgrounds/question.png);
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .logo-box {
    width: 200px;
    margin: 40px auto 0;
  }
  h2 {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 60px;
    font-family: "Playball" !important;
    font-weight: 400;
    color: #ffbd3a;
    text-align: center;
  }
  p {
    text-align: center;
    margin-top: 15px;
    font-size: 22px;
    font-family: system-ui;
    text-align: center;
    font-style: italic;
    font-weight: 100;
  }
}
