.course-video-wrapper {
  .video-header {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0;
    width: 100%;
    .close-wrapper {
      left: 10px;
      color: #686868;
      position: absolute;
      svg {
        width: 25px;
        height: 25px;
        @media screen and (max-width: 763px) {
          width: 20px;
          height: 20px;
        }
      }
      @media screen and (max-width: 763px) {
        left: 0;
        min-width: auto;
      }
    }
    h4 {
      font-size: 22px;
      font-weight: 500;
      text-align: center;
      width: 85%;
      margin: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      @media screen and (max-width: 763px) {
        width: 80%;
        font-size: 18px;
      }
    }
    .options-box {
      right: 10px;
      position: absolute;
      img {
        width: 20px;
      }
      .switch-box {
        position: absolute;
        right: 10px;
        .switch-wrapper {
          position: relative;
          display: inline-block;
          width: 37px;
          margin-left: 20px;
          height: 22px;
          top: 2px;
          input {
            opacity: 0;
            width: 0;
            height: 0;
          }
          .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: 0.4s;
            transition: 0.4s;
            border-radius: 34px;
            &:before {
              position: absolute;
              content: "";
              height: 17px;
              width: 16px;
              border-radius: 50%;
              left: 2px;
              bottom: 3px;
              background-color: white;
              -webkit-transition: 0.4s;
              transition: 0.4s;
            }
          }
          input:checked + .slider {
            background-color: #00bac7;
          }
          input:focus + .slider {
            box-shadow: 0 0 1px #00bac7;
          }
          input:checked + .slider:before {
            -webkit-transform: translateX(18px);
            -ms-transform: translateX(18px);
            transform: translateX(18px);
          }
        }
      }
    }
  }
  .video-player-wrapper {
    width: 65%;
    margin: 70px auto 0;
    border-radius: 20px;
    overflow: hidden;
    border: 3px solid #ffffff;
    @media screen and (max-width: 991px) {
      width: 90%;
    }
  }
}
