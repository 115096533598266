.course-video-player-wrapper {
  background: #fff;
  position: relative;
  padding-bottom: 10px;
  border-radius: 9px;
  overflow: hidden;
  .player-wrapper {
    height: 400px;
    position: relative;
    overflow: hidden;
    .video-player {
      cursor: pointer;
      height: 99%;
      width: 100%;
      background: #fff;
    }
    @media screen and (max-width: 763px) {
      height: 320px;
    }
  }
  .video-markers {
    width: 88%;
    position: relative;
    top: 22px;
    pointer-events: none;
    margin: auto;
    span {
      position: absolute;
      left: 0;
      img {
        position: relative;
        left: -18px;
        max-width: none;
        width: 30px;
        @media screen and (max-width: 763px) {
          width: 25px;
          left: -15px;
        }
      }
    }
    @media screen and (max-width: 1246px) {
      width: 83%;
    }
    @media screen and (max-width: 763px) {
      width: 70%;
      top: 14px;
    }
  }
  .controls-wrapper {
    overflow: hidden;
    padding: 5px 10px;
    padding-top: 20px;
    button {
      color: #00bac7;
      svg {
        width: 30px;
        height: 30px;
      }
    }
    .duration-wrapper {
      display: flex;
      width: 97%;
      padding-left: 5px;
      position: relative;
      top: 2px;
      margin: auto;
      pointer-events: none;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 14px;
        font-weight: 400;
        @media screen and (max-width: 763px) {
          font-size: 12px;
        }
      }
      @media screen and (max-width: 991px) {
        width: 94%;
        padding-left: 0;
        top: 5px;
      }
      @media screen and (max-width: 763px) {
        width: 90%;
      }
    }
    .pause-wrapper {
      width: 5%;
      float: left;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      bottom: 7px;
      @media screen and (max-width: 991px) {
        bottom: 4px;
        width: 8%;
      }
      @media screen and (max-width: 763px) {
        width: 15%;
        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
    .progress-wrapper {
      width: 90%;
      float: left;
      display: flex;
      align-items: center;

      @media screen and (max-width: 1246px) {
        .video-progress {
          width: 95%;
          margin: 0 auto;
        }
      }
      @media screen and (max-width: 991px) {
        width: 85%;
        .video-progress {
          width: 100%;
        }
      }
      @media screen and (max-width: 763px) {
        width: 70%;
      }
    }
    .volume-wrapper {
      width: 5%;
      position: relative;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 7px;
      .volume-bar {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;
      }
      @media screen and (max-width: 991px) {
        bottom: 4px;
      }
      @media screen and (max-width: 763px) {
        width: 15%;
        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
    @media screen and (max-width: 763px) {
      padding: 10px 0;
    }
  }
  .video-footer {
    padding: 0 15px;
    .logo-box {
      width: 100px;
      @media screen and (max-width: 763px) {
        width: 70px;
        margin-right: 20px;
      }
    }
    .next-question {
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #d5f6f9;
        border-radius: 30px;
        font-weight: 500;
        font-size: 14px;
        color: #00bac7;
        padding: 9px 10px;
        width: 165px;
        max-width: 100%;
        // min-height: 35px;
        @media screen and (max-width: 763px) {
          width: 130px;
          padding: 5px;
          min-height: auto;
          font-size: 12px;
        }
      }
    }
    .link-action-wrapper {
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button {
        background: #00bac7;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        margin: 0 5px;
        padding: 6px 15px;
        min-width: 90px;
        color: #fff;
        text-transform: none;
        img {
          margin-right: 5px;
          @media screen and (max-width: 991px) {
            margin: 0;
            width: 90%;
          }
        }
        @media screen and (max-width: 991px) {
          padding: 8px 6px;
          min-width: auto;
          width: 30px;
          span {
            display: none;
          }
        }
        @media screen and (max-width: 1246px) {
        }
        // @media screen and (max-width: 1194px) {
        //   &:nth-child(1) {
        //     display: none;
        //   }
        // }
      }
    }
  }
}
