.discord {
  text-align: center;
  width: 100%;
  font-size: x-large;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: url("../../assets/backgrounds/404.png") #00bac7;
button{
  margin-top: 20px;
  color: white;
  background: #007179;
  padding: 5px 40px;
  border-radius: 10px;
  font-weight: 600;

  &:hover{
    background: #007179;
  }
}
}