@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;700&display=swap");
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "Baloo 2" !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
body {
  background: url(assets/backgrounds/body.jpg);
  background-position: unset;
  background-size: cover;
  background-attachment: fixed;
  min-height: 86vh;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #348a90;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #24696e;
  }
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
  font-size: 6px;
}
.transition {
  transition: 0.5s all !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.custom-scroll::-webkit-scrollbar {
  width: 6px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #dadada;
}
.Toastify__toast-theme--light.Toastify__toast--success
  .Toastify__toast-body
  .Toastify__toast-icon
  svg {
  fill: #00bac7 !important;
}
.Toastify__toast-theme--light.Toastify__toast--success .Toastify__progress-bar {
  background: #00bac7 !important;
}

.selected-video {
  cursor: pointer;
  background: border-box;
  border: 2px solid #00bac7;
  border-radius: 15px;
  padding: 5px;
}
