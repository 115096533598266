.loading-screen {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  color: #424955;
  font-size: 45px;
  font-weight: 700;
  pointer-events: none;
  .dot-typing {
    margin-left: 25px;
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    bottom: -6px;
    border-radius: 5px;
    background-color: #00bac7;
    color: #00bac7;
    box-shadow: 9984px 0 0 0 #00bac7, 9999px 0 0 0 #00bac7,
      10014px 0 0 0 #00bac7;
    animation: dotTyping 1.5s infinite linear;
  }
  &.small {
    font-size: 30px;
    position: relative;
    padding-top: 20px;
    .dot-typing {
      bottom: -3px;
    }
  }
  @media screen and (max-width: 763px) {
    font-size: 30px;
    &.small {
      font-size: 20px;
    }
  }
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #00bac7, 9999px 0 0 0 #00bac7,
      10014px 0 0 0 #00bac7;
  }
  16.667% {
    box-shadow: 9984px -10px 0 0 #00bac7, 9999px 0 0 0 #00bac7,
      10014px 0 0 0 #00bac7;
  }
  33.333% {
    box-shadow: 9984px 0 0 0 #00bac7, 9999px 0 0 0 #00bac7,
      10014px 0 0 0 #00bac7;
  }
  50% {
    box-shadow: 9984px 0 0 0 #00bac7, 9999px -10px 0 0 #00bac7,
      10014px 0 0 0 #00bac7;
  }
  66.667% {
    box-shadow: 9984px 0 0 0 #00bac7, 9999px 0 0 0 #00bac7,
      10014px 0 0 0 #00bac7;
  }
  83.333% {
    box-shadow: 9984px 0 0 0 #00bac7, 9999px 0 0 0 #00bac7,
      10014px -10px 0 0 #00bac7;
  }
  100% {
    box-shadow: 9984px 0 0 0 #00bac7, 9999px 0 0 0 #00bac7,
      10014px 0 0 0 #00bac7;
  }
}
