.Doc-wraper {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background: url("../../assets/backgrounds/404.png") #00bac7;
  .content {
    width: 700px;
    max-width: 100%;
    h2 {
      font-size: 18vw;
      letter-spacing: 5px;
      text-shadow: 10px 0 0 #fff;
      color: #fff;
      font-weight: 900;
      line-height: 1em;
    }
    h4 {
      font-size: 1.4em;
      text-transform: capitalize;
      margin-bottom: 16px;
      color: #dadada;
      font-weight: 600;
      padding: 5px 25px;
    }
    p {
      font-size: 18px;
      font-weight: lighter;
      margin-bottom: 10px;
      text-align: justify
    }
    a {
      display: inline-block;
      padding: 15px 35px;
      background: #007179;
      color: #fff;
      text-decoration: none;
      margin-top: 10px;
      border-radius: 8px;
      text-transform: capitalize;
      font-size: 14px;
      font-weight: bold;
      transition: 0.5s all;
      &:hover {
        background: #00565c;
      }
    }
    .adding{
      text-align: left;
    }
    
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #348a90;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #24696e;
  }
  }

}
