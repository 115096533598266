.zoom-app-wraper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 55px;

  .logoBox {
    width: 100px;
    position: absolute;
    top: 12px;
    left: 90px;

    a {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h2 {
    position: relative;
    color: #18223a;
    width: 50%;
    margin: auto;
    font-weight: 600;
    font-size: 50px;
    line-height: 60px;
    /* identical to box height, or 120% */

    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 19px;
      top: -20px;
      width: 70px;
      height: 90px;
      background-size: 50px;
      background-repeat: no-repeat;
      background-image: url(../../assets/icons/pagetitle-dec.svg);

      // @media screen and (max-width: 1200px) {
      //   display: none;
      // }
    }

    @media screen and (max-width: 1200px) {
      font-size: 34px;
      line-height: 40px;

    }

    @media screen and (max-width: 991px) {
      width: 70%;
      margin-bottom: 10px;
    }

    @media screen and (max-width: 763px) {
      font-size: 34px;
      width: 90%;
    }
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #18223A;
  }

  .zoom-logo {
    display: flex;
    gap: 25px;
    margin-top: 50px;
  }

  button {

    background: #00BAC7;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;
    align-items: center;
    padding: 16px 40px;
    gap: 10px;
    margin-top: 50px;

    img {
      width: 100%;
      cursor: pointer;
    }
  }
}